<template>
  <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
    <template #title>
      <h3 class="m-0">
        {{ actionText + '請休假' }}
      </h3>
    </template>
    <el-form v-if="modalShow" :ref="formRefs" :model="row" :rules="rules" label-width="100px">
      <div class="card">
        <div class="card-body p-3">
          <div class="form-row">
            <el-form-item class="col-md-6" label="申請人" prop="user_id">
              <el-select
                v-model.number="row.user_id"
                placeholder="- 請選擇申請人 -"
                :disabled="formAction != 'create'"
                filterable
                @change="onUserChange"
              >
                <el-option
                  v-for="u in $store.getters['user/apply']"
                  :key="u.id"
                  :label="u.name"
                  :value="Number(u.id)"
                />
              </el-select>
            </el-form-item>
            <el-form-item v-show="row.created_at" class="col-md-6" label="申請時間">
              <h6>{{ row.created_at }}</h6>
            </el-form-item>
            <div class="w-100"></div>
            <el-form-item v-show="row.updated_at" class="col-md-6" label="審核狀態">
              <h6>
                <b-badge :variant="colors[row.is_approved]">{{ leaveApplyStatus[row.is_approved] }}</b-badge>
              </h6>
            </el-form-item>
            <el-form-item v-show="row.is_approved != 2" class="col-md-6" label="審核時間">
              <h6>{{ row.updated_at }}</h6>
            </el-form-item>
            <div class="w-100"></div>
            <el-form-item class="col-md-6" label="假別" prop="leave_id">
              <el-select
                v-model.number="row.leave_id"
                placeholder="- 請選擇假別 -"
                :disabled="row.is_approved != 2"
                @change="onLeaveChange"
              >
                <el-option v-for="l in leavesForUser" :key="l.id" :label="l.name" :value="Number(l.id)" />
              </el-select>
            </el-form-item>
            <el-form-item class="col-md-6" label="項目" prop="item_id">
              <el-select
                v-model.number="row.item_id"
                :disabled="row.is_approved != 2"
                placeholder="- 請選擇項目 -"
                @change="onLeaveItemChange"
              >
                <el-option v-for="(li, index) in leaveItems" :key="index" :label="li.title" :value="Number(index)" />
              </el-select>
            </el-form-item>
            <el-form-item
              v-show="row.item_id !== null && (availableHours > 1 || row.leave.is_separate)"
              class="col-md-6"
              label="可請總時數"
            >
              <h6>
                共 {{ `${availableHours} 小時` }}
                {{
                  availableHours / basicHours > 0
                    ? `( ${Math.floor(availableHours / basicHours)} 天 ${availableHours % basicHours} 小時 )`
                    : ''
                }}
              </h6>
            </el-form-item>
            <el-form-item
              v-show="row.item_id !== null && (row.leave.is_separate || row.leave.id == 1)"
              class="col-md-6"
              label="已請總時數"
            >
              <h6>
                共 {{ `${usedHours} 小時` }}
                {{
                  usedHours / basicHours > 0
                    ? `( ${Math.floor(usedHours / basicHours)} 天 ${usedHours % basicHours} 小時 )`
                    : ''
                }}
              </h6>
            </el-form-item>
            <div class="w-100"></div>
            <el-form-item class="col-md-12 col-lg-6" label="開始時間" required>
              <div class="form-row">
                <div class="col-7">
                  <el-form-item class="mb-0" prop="timeRange.start.date">
                    <el-date-picker
                      ref="date1"
                      v-model="row.timeRange.start.date"
                      :disabled="row.item_id == null || row.is_approved != 2"
                      :default-value="format(Date.now(), 'YYYY-MM-DD')"
                      value-format="yyyy-MM-dd"
                      type="date"
                      placeholder="開始日期"
                    />
                  </el-form-item>
                </div>
                <div class="col-5">
                  <el-form-item class="mb-0" prop="timeRange.start.time">
                    <el-time-select
                      ref="time1"
                      v-model="row.timeRange.start.time"
                      :picker-options="startTimePickerOptions"
                      :disabled="
                        row.item_id == null ||
                        (row.leave && row.leave.min_hours && row.leave.min_hours >= basicHours) ||
                        row.is_approved != 2
                      "
                      placeholder="開始時間"
                    />
                  </el-form-item>
                </div>
              </div>
            </el-form-item>
            <el-form-item class="col-md-12 col-lg-6" label="結束時間" required>
              <div class="form-row">
                <div class="col-7">
                  <el-form-item class="mb-0" prop="timeRange.end.date">
                    <el-date-picker
                      ref="date2"
                      v-model="row.timeRange.end.date"
                      :disabled="row.item_id == null || row.is_approved != 2"
                      :default-value="format(Date.now(), 'YYYY-MM-DD')"
                      :picker-options="endDatePickerOptions"
                      value-format="yyyy-MM-dd"
                      type="date"
                      placeholder="結束日期"
                    />
                  </el-form-item>
                </div>
                <div class="col-5">
                  <el-form-item class="mb-0" prop="timeRange.end.time">
                    <el-time-select
                      ref="time2"
                      v-model="row.timeRange.end.time"
                      :picker-options="endTimePickerOptions"
                      :disabled="
                        row.item_id == null ||
                        (row.leave && row.leave.min_hours && row.leave.min_hours >= basicHours) ||
                        row.is_approved != 2
                      "
                      placeholder="結束時間"
                    />
                  </el-form-item>
                </div>
              </div>
            </el-form-item>
            <el-form-item class="col-md-12 col-lg-6" label="請假時數">
              <h6>
                共 {{ `${applyHours} 小時` }}
                {{
                  applyHours / basicHours > 0
                    ? `( ${Math.floor(applyHours / basicHours)} 天 ${applyHours % basicHours} 小時 )`
                    : ''
                }}
              </h6>
            </el-form-item>
            <el-form-item
              v-show="!row.leave.is_ignore_holidays && period.holidays && period.holidays.length > 0"
              class="col-md-6"
              label="排休假"
            >
              <h6>{{ period.holidays.join() }}</h6>
            </el-form-item>
            <el-form-item
              v-show="row.item_id !== null && row.event && row.event.deadline && row.event.leave_id !== 1"
              class="col-md-6"
              label="截止日期"
            >
              <h6>
                {{ row.event && row.event.deadline }}
              </h6>
            </el-form-item>
          </div>
          <div class="form-row">
            <el-form-item v-show="row.is_approved == 2" class="col" label="審核">
              <el-switch
                v-model="isAllow"
                :active-value="1"
                :inactive-value="0"
                active-text="直接核准"
                inactive-text="主管審核"
              />
            </el-form-item>
          </div>
          <div class="form-row">
            <el-form-item class="col" label="備註" prop="comment">
              <el-input v-model="row.comment" placeholder="備註" />
            </el-form-item>
          </div>
          <div class="form-row">
            <el-form-item class="col" label="請假證明" label-width="100px">
              <image-upload v-model="pictureList" action="/image" />
            </el-form-item>
          </div>
        </div>
      </div>
      <div class="form-row mt-3">
        <div class="col text-right">
          <b-btn variant="secondary" @click.prevent="hideModal"><fa icon="times-circle" fixed-width />取消</b-btn>
          <b-btn :disabled="submiting" type="submit" variant="primary" @click.prevent="onSubmit">
            <fa icon="edit" fixed-width />{{ actionText }}
          </b-btn>
        </div>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { differenceBy, find, debounce } from 'lodash-es'
import { parse, format, addDays } from 'date-fns'
import { mapActions } from 'vuex'
import { modify } from '@/plugins/mixins'
import { leaveApplyStatus, colors } from '@/plugins/const'

export default {
  name: 'LeaveApplySetting',
  mixins: [modify],
  props: {
    userId: {
      validator: (prop) => typeof prop === 'number' || prop === null,
      default: null,
    },
    initDate: {
      validator: (prop) => typeof prop === 'string' || prop === null,
      default: null,
    },
  },
  data() {
    return {
      row: {
        id: null,
        leave_event_id: null,
        user_id: null,
        leave_id: null,
        item_id: null,
        is_approved: 2,
        start_at: null,
        end_at: null,
        days: 0,
        hours: 0,
        comment: null,
        created_at: null,
        updated_at: null,
        user: {
          id: null,
          name: null,
          shift_id: null,
          sex: 0,
          shift: {
            id: null,
            type: 0,
            is_annual_leave: 1,
            basic_hours: 8,
            period: [
              {
                end_at: '18:00',
                start_at: '08:30',
                rest_end_at: '13:30',
                rest_start_at: '12:00',
              },
            ],
            annual_leave: {
              min_hours: 2,
              apply_before: 3,
              is_consecutive: 1,
              more_than_days: 3,
              more_than_days_apply_before: 7,
            },
          },
        },
        leave: {
          id: null,
          is_enable: 1,
          is_female_only: 0,
          min_hours: 1,
          is_separate: 0,
          items: [],
        },
        event: {},
        audits: [],
        timeRange: {
          start: { date: null, time: null },
          end: { date: null, time: null },
        },
        media: [],
        new_media: [],
      },
      rules: {
        user_id: [{ required: true, message: '請選擇申請人', trigger: 'change' }],
        leave_id: [{ required: true, message: '請選擇假別', trigger: 'blur' }],
        item_id: [{ required: true, message: '請選擇項目', trigger: 'blur' }],
        'timeRange.start.date': [{ required: true, message: '請選擇請開始日期', trigger: 'blur' }],
        'timeRange.start.time': [{ required: true, message: '請選擇請開始時間', trigger: 'blur' }],
        'timeRange.end.date': [{ required: true, message: '請選擇請結束日期', trigger: 'blur' }],
        'timeRange.end.time': [{ required: true, message: '請選擇請結束時間', trigger: 'blur' }],
      },
      startTimePickerOptions: {
        start: '08:30',
        step: '00:30',
        end: '18:00',
      },
      endDatePickerOptions: {
        disabledDate: this.disabledDate,
      },
      endTimePickerOptions: {
        start: '08:30',
        step: '00:30',
        end: '18:00',
        minTime: null,
      },
      pictureList: [],
      isAllow: false,
      modalMaxWidth: 1000,
      leaveApplyStatus,
      colors,
      attendances: {},
      period: {
        ranges: [],
        holidays: [],
        hours: 0,
      },
    }
  },
  computed: {
    leavesForUser() {
      return this.$store.getters['leave/user'](this.row.user?.sex || 0, this.row.user?.shift?.is_annual_leave || 0)
    },
    leaveItems() {
      return this.row.leave?.items || []
    },
    applyHours() {
      let hours = 0
      if (!this.isSetTimeRange()) {
        return hours
      }
      return this.period?.hours || 0
    },
    availableHours() {
      let hours = 0
      let days = this.row.leave?.items[this.row.item_id]?.days || 0

      if (
        (this.row.leave_id != null && this.row.item_id != null && days > 1) ||
        this.row.leave?.is_separate ||
        (this.row.event?.id && this.row.leave_id == 1 && this.row.item_id == 0)
      ) {
        hours = this.row.leave_id == 1 ? this.row.event?.total : days * this.basicHours
      }

      return hours
    },
    usedHours() {
      return this.row.event?.used_hours || 0
    },
    basicHours() {
      return this.row.user?.shift?.basic_hours || 8
    },
    startSerial() {
      return this.row.timeRange.start.date &&
        Object.prototype.hasOwnProperty.call(this.attendances, this.row.timeRange.start.date)
        ? this.attendances[this.row.timeRange.start.date]
        : 0
    },
    endSerial() {
      return this.row.timeRange.end.date &&
        Object.prototype.hasOwnProperty.call(this.attendances, this.row.timeRange.end.date)
        ? this.attendances[this.row.timeRange.end.date]
        : 0
    },
  },
  watch: {
    startSerial: {
      handler(val) {
        this.updateTimePicker()
        if (this.row.timeRange.start.date && this.row.leave?.min_hours >= this.basicHours) {
          this.$set(this.row.timeRange.start, 'time', this.row.user.shift.period[val].start_at)
        }

        // console.log(val, this.row.timeRange.start)
      },
      immediate: true,
    },
    endSerial: {
      handler(val) {
        this.updateTimePicker()
        if (this.row.timeRange.end.date && this.row.leave?.min_hours >= this.basicHours) {
          this.$set(this.row.timeRange.end, 'time', this.row.user.shift.period[val].end_at)
        }
        // console.log(val, this.row.timeRange.start)
      },
      immediate: true,
    },
    'row.timeRange.start.date'(val) {
      if (!val) {
        this.resetTimeRange()
        return
      }

      if (val && !Object.prototype.hasOwnProperty.call(this.attendances, val)) {
        this.getSerial({ userId: this.row.user_id, date: val }).then((serial) => {
          this.$set(this.attendances, val, serial)
        })
      }

      if (val && this.row.leave?.min_hours >= this.basicHours) {
        this.$set(this.row.timeRange.start, 'time', this.row.user.shift.period[this.startSerial].start_at)
      }

      let item = this.row.leave.items[this.row.item_id]
      let days = item?.days || 0

      if (days > 1 && this.row.leave.is_ignore_holidays == 1 && this.row.leave.min_hours >= this.basicHours) {
        this.$set(this.row.timeRange.end, 'date', format(addDays(parse(val), days - 1), 'YYYY-MM-DD'))
      } else {
        // this.$set(this.row.timeRange.end, 'date', val)
      }

      this.updateTimePicker()
      this.$set(
        this.row,
        'start_at',
        val && this.row.timeRange.start.time ? `${val} ${this.row.timeRange.start.time}:00` : null
      )
    },
    'row.timeRange.start.time'(val) {
      this.updateTimePicker()
      this.$set(
        this.row,
        'start_at',
        val && this.row.timeRange.start.date ? `${this.row.timeRange.start.date} ${val}:00` : null
      )
    },
    'row.timeRange.end.date'(val) {
      if (val && !Object.prototype.hasOwnProperty.call(this.attendances, val) && val != this.row.timeRange.start.date) {
        this.getSerial({ userId: this.row.user_id, date: val }).then((serial) => {
          this.$set(this.attendances, val, serial)
        })
      }

      if (val && this.row.leave?.min_hours >= this.basicHours) {
        this.$set(this.row.timeRange.end, 'time', this.row.user.shift.period[this.endSerial].end_at)
      }

      this.$set(
        this.row,
        'end_at',
        val && this.row.timeRange.end.time ? `${val} ${this.row.timeRange.end.time}:00` : null
      )
    },
    'row.timeRange.end.time'(val) {
      this.$set(
        this.row,
        'end_at',
        val && this.row.timeRange.end.date ? `${this.row.timeRange.end.date} ${val}:00` : null
      )
    },
    'row.end_at': debounce(function (val) {
      if (val && this.isSetTimeRange()) {
        this.checkPeriod({
          userId: this.row.user_id,
          leaveId: this.row.leave_id,
          start: this.row.start_at,
          end: this.row.end_at,
        })
          .then((data) => {
            this.$set(this.$data, 'period', data)
            this.$set(this.$data, 'attendances', data.attendances)

            // 檢查可分段請假的以及產假
            if (this.row.leave?.is_separate || [7, 8].includes(this.row.leave?.id)) {
              let remainHours = this.availableHours
              if (this.row.is_approved != 1) {
                remainHours -= this.usedHours
              }

              if (data.hours > remainHours) {
                this.$message({
                  type: 'error',
                  message: '請假時數超過可請假時數，請重新確認請假時間。',
                  duration: 5000,
                })
                this.$set(this.row.timeRange, 'end', { date: null, time: null })
              }
            }
          })
          .catch(({ data }) => {
            this.$message({
              type: 'error',
              message: data.message,
              duration: 5000,
            })
            this.$set(this.row.timeRange, 'end', { date: null, time: null })
          })
      }
    }, 500),
    'row.media': {
      handler() {
        this.pictureList = [].concat(this.row.media)
        this.row.new_media = []
      },
      deep: true,
    },
    pictureList(n) {
      this.row.new_media = differenceBy(n, this.row.media, 'url')
    },
  },
  methods: {
    ...mapActions('leaveApply', ['fetchOne', 'doCreate', 'doUpdate', 'checkEvent', 'checkPeriod']),
    ...mapActions('attendance', ['getSerial']),
    resetRow() {
      this.$set(this.$data, 'row', {
        id: null,
        leave_event_id: null,
        user_id: this.userId || null,
        leave_id: null,
        item_id: null,
        is_approved: 2,
        start_at: null,
        end_at: null,
        days: 0,
        hours: 0,
        comment: null,
        created_at: null,
        updated_at: null,
        user: {
          id: null,
          name: null,
          shift_id: null,
          sex: 0,
          shift: {
            id: null,
            type: 0,
            is_annual_leave: 1,
            basic_hours: 8,
            period: [
              {
                end_at: '18:00',
                start_at: '08:30',
                rest_end_at: '13:30',
                rest_start_at: '12:00',
              },
            ],
            annual_leave: {
              min_hours: 2,
              apply_before: 3,
              is_consecutive: 1,
              more_than_days: 3,
              more_than_days_apply_before: 7,
            },
          },
        },
        leave: {
          id: null,
          is_enable: 1,
          is_female_only: 0,
          min_hours: 1,
          is_separate: 0,
          items: [],
        },
        event: {},
        audits: [],
        timeRange: {
          start: { date: this.initDate, time: null },
          end: { date: this.initDate, time: null },
        },
        media: [],
        new_media: [],
      })
      this.pictureList = []
      this.attendances = {}
    },
    isSetTimeRange() {
      return (
        this.row.timeRange.start.date &&
        this.row.timeRange.start.time &&
        this.row.timeRange.end.date &&
        this.row.timeRange.end.time
      )
    },
    resetTimeRange() {
      this.$set(this.row, 'timeRange', {
        start: { date: this.initDate, time: null },
        end: { date: this.initDate, time: null },
      })
      this.$set(this.$data, 'period', { ranges: [], holidays: [], hours: 0 })
      this.$set(this.$data, 'attendances', {})
    },
    async updateTimePicker() {
      await this.$forceUpdate()
      let startPeriod = this.row.user.shift?.period[this.startSerial]
      if (parse('2011-01-01 ' + startPeriod.start_at + ':00') > parse('2011-01-01 ' + startPeriod.end_at + ':00')) {
        startPeriod = { start_at: '00:00', end_at: '23:30' }
      }

      this.$set(this.$data, 'startTimePickerOptions', {
        start: startPeriod.start_at,
        end: startPeriod.end_at,
        step: '00:30',
      })

      let minTime = null

      if (
        this.row.leave_id &&
        this.row.leave?.min_hours &&
        this.row.timeRange.start.date == this.row.timeRange.end.date &&
        this.row.timeRange.start.time
      ) {
        let time = this.row.timeRange.start.time.split(':')
        let date = new Date()
        let minHours =
          this.row.leave_id == 1
            ? this.row.user.shift.annual_leave.min_hours
            : this.row.leave.min_hours >= this.basicHours
            ? 1
            : this.row.leave.min_hours
        date.setHours(+time[0] + minHours, +time[1] - 1, 0)
        minTime = format(date, 'HH:mm')
      }

      let endPeriod = this.row.user.shift?.period[this.endSerial]
      if (parse('2011-01-01 ' + endPeriod.start_at + ':00') > parse('2011-01-01 ' + endPeriod.end_at + ':00')) {
        endPeriod = { start_at: '00:00', end_at: '23:30' }
      }

      this.$set(this.$data, 'endTimePickerOptions', {
        start: endPeriod.start_at,
        end: endPeriod.end_at,
        step: '00:30',
        minTime,
      })
    },
    disabledDate(time) {
      return time < parse(this.row.timeRange.start.date)
    },
    onUserChange(userId) {
      if (userId) {
        let user = this.$store.getters['user/all'].find((x) => x.id == +userId)
        let shift = this.$store.getters['shift/all'].find((x) => x.id == +user.shift_id)

        // 到職未滿六個月沒有特休
        if (user.seniority < 6) {
          shift.is_annual_leave = 0
        }

        user.shift = shift
        this.$set(this.row, 'user', user)
      }

      // 清空 leave_id
      if (this.row.leave_id !== null) {
        this.$set(this.row, 'leave_id', null)
        this.$set(this.row, 'item_id', null)
      }

      this.resetTimeRange()
    },
    onLeaveChange(leaveId) {
      this.resetTimeRange()

      if (leaveId) {
        let leave = find(this.$store.getters['leave/all'], { id: +leaveId })
        this.$set(this.row, 'leave', leave)
        this.$set(this.row, 'item_id', leave.items.length > 1 ? null : 0)
        this.$set(this.row, 'leave_event_id', null)
        this.$set(this.row, 'event', {})

        // check event for 特休假 & 產檢假
        if (this.row.user_id != null && [1, 6].includes(this.row.leave_id) && this.row.item_id == 0) {
          this.checkEvent({ userId: this.row.user_id, leaveId: leaveId, itemId: 0 }).then((event) => {
            this.$set(this.row, 'leave_event_id', event.id || null)
            this.$set(this.row, 'event', event || {})
          })
        }
      } else {
        this.$set(this.row, 'leave', {
          id: null,
          is_enable: 1,
          is_female_only: 0,
          min_hours: 1,
          is_separate: 0,
          items: [{ days: null }],
        })
        this.$set(this.row, 'item_id', null)
      }
    },
    onLeaveItemChange() {
      this.resetTimeRange()

      // check event
      if (this.row.user_id != null && this.row.leave_id != null && this.row.item_id != null) {
        this.checkEvent({ userId: this.row.user_id, leaveId: this.row.leave_id, itemId: this.row.item_id }).then(
          (event) => {
            this.$set(this.row, 'leave_event_id', event.id || null)
            this.$set(this.row, 'event', event || {})
          }
        )
      }
    },
    onBeforeSubmit() {
      if (this.row.is_approved == 2 && this.isAllow == 1) {
        this.row.is_approved = 1
      }
      return true
    },
    onOpen() {
      // 修正 el-time-select 怪問題
      if (this.$refs.time1) {
        this.$refs.time1.userInput = null
        this.$refs.time1.valueOnOpen = null
      }

      if (this.$refs.time2) {
        this.$refs.time2.userInput = null
        this.$refs.time2.valueOnOpen = null
      }

      // 如果 props 的 userId 有值時
      if (this.userId) {
        this.onUserChange(this.userId)
      }
    },
    onClosed() {
      this.isAllow = false
    },
    format,
    parse,
  },
}
</script>
